import React from "react"
import { Icon } from "helpers/getIcon"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import {
  StaffContainer,
  StaffTeaser,
  StaffContact,
  StaffIconEmailContainer,
} from "./style"

const Staff = ({ staffData }) => {
  return (
    <StaffContainer>
      <FlexGrid fullWidth gap={[28, 20, 30, 40]}>
        <FlexRow>
          {staffData?.map((person, index) => {
            return (
              <FlexCol key={index} xs={12} c1={[600, 6]} md={4}>
                <StaffTeaser
                  title={person?.title}
                  tag={person?.staffDetails?.position}
                  img={person?.staffDetails?.image}
                  noLink
                >
                  <StaffIconEmailContainer>
                    <Icon name="staff-email" />
                    <StaffContact
                      href={`mailto: ${person?.staffDetails?.email}`}
                    >
                      {person?.staffDetails?.email}
                    </StaffContact>
                  </StaffIconEmailContainer>
                  <StaffIconEmailContainer>
                    <Icon name="staff-phone" />
                    <StaffContact href={`tel: ${person?.staffDetails?.phone}`}>
                      {person?.staffDetails?.phone}
                    </StaffContact>
                  </StaffIconEmailContainer>
                  <StaffIconEmailContainer>
                    {person?.staffDetails?.linkedin && (
                      <>
                        <Icon name="linkedin" />
                        <StaffContact href={person?.staffDetails?.linkedin}>
                          Connect on Linkedin
                        </StaffContact>
                      </>
                    )}
                  </StaffIconEmailContainer>
                </StaffTeaser>
              </FlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
    </StaffContainer>
  )
}

export default Staff
