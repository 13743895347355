import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPage } from "components/InternalPage"
import { Stats } from "../components/Stats"
import { Staff } from "../components/Staff"
import { useStaticQuery, graphql } from "gatsby"

const StaffPage = ({ pageContext }) => {
  const {
    content,
    featuredImage,
    legAncestors,
    previewSQ,
    depth,
    seo,
    statsModule,
    staffBlock,
  } = pageContext

  const data = useStaticQuery(
    graphql`
      query {
        staffCategories: wpgraphql {
          categories(where: { name: "Staff Type" }) {
            nodes {
              name
              children {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  )

  const staffData = staffBlock?.staffBlock
  const headerTitle = staffBlock?.staffBlock?.staffblockheader
  const rawStaffDetails = staffData?.staffblockpeople
  const noNullStaffs = []
  
  const filterNullStaffs = () => {
    rawStaffDetails.map(singleStaff => {
      if (singleStaff !== null) {
        return noNullStaffs.push(singleStaff)
      }
    })
  }
  filterNullStaffs()
  const staffDetails = noNullStaffs
  const staffCategories =
    data?.staffCategories?.categories?.nodes[0]?.children?.nodes
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(staffDetails)
  const [filter, setFilter] = useState("all")

  const findResults = () => {
    const newData = []
    staffDetails.filter(el => {
      return filter === "all" || filter.value === "all"
      ? newData.push(el)
      : el?.staffDetails?.staffType.map(item => {
        const decodedName = item.name?.replace("&amp;", "&")
        decodedName.indexOf(filter?.value) !== -1 && newData.push(el)
          })
    })
    setCurrentPosts(newData)
  }

  useEffect(() => {
    findResults()
  }, [filter])

  return (
    <Layout
      ancestors={legAncestors}
      internal
      headerTitle={headerTitle}
      headerImg={featuredImage}
      preview={previewSQ}
      landing={true}
      depth={depth}
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={featuredImage?.sourceUrl}
      />
      <InternalPage
        content={content}
        landing={true}
        title={headerTitle}
        setCurrentPage={setCurrentPage}
        setFilter={setFilter}
        staff
        categories={staffCategories}
      />

      <Staff staffData={currentPosts} />
      {statsModule && <Stats landing data={statsModule} />}
    </Layout>
  )
}

export default StaffPage
