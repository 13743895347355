import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import { Teaser } from "../Teaser"
import { Title, Tag, ImageContainer, InfoContainer } from "../Teaser/style"

export const StaffContainer = styled(MainColumn)`
  margin-bottom: 60px;
`

export const StaffTeaser = styled(Teaser)`
  background: #fff;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  ${ImageContainer} {
    padding-top: 56%;
  }
  ${InfoContainer} {
    padding: 11px 0 25px 25px;
    @media ${device.desktop} {
      padding: 23px 0 60px 40px;
    }
  }
  ${Title} {
    font-weight: 600;
    font-size: 20px;
    color: #303b53;
    letter-spacing: -0.38px;
    line-height: 28px;
    margin-bottom: 0;
    @media ${device.desktop} {
      font-size: 24px;
      line-height: 28px;
    }
  }
  ${Tag} {
    font-weight: 600;
    font-size: 12px;
    color: #676e7b;
    letter-spacing: -0.22px;
    line-height: 16px;
    margin-bottom: 13px;

    @media ${device.desktop} {
      line-height: 27px;
      font-size: 14px;
    }
  }
`

export const StaffContact = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #303b53;
  letter-spacing: -0.28px;
  position: relative;

  @media ${device.desktop} {
    font-size: 18px;
  }

  :before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: ${props => props.theme.peach};
  }

  &:hover:before {
    display: none;
  }
`

export const StaffIconEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;

  @media (min-width: 600px) {
    min-height: 24px;
  }

  @media ${device.desktop} {
    margin-bottom: 15px;
  }

  svg {
    width: 16px;
    height: 14px;
    margin-right: 14px;

    @media ${device.desktop} {
      width: 16px;
      height: 16px;
    }
  }
`
